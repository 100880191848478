import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../../components/Layout';
import { LightgalleryItem } from "react-lightgallery";
import img1 from '../../assets/images/bortrezor/1.jpg'
import img2 from '../../assets/images/bortrezor/2.jpg'
import img3 from '../../assets/images/bortrezor/3.jpg'
import img4 from '../../assets/images/bortrezor/4.jpg'

const TrezorPage = () => (
  <Layout page="bortrezor">
    <article id="main">
      <header>
        <h2>WINE SAFE-DEPOSIT VAULT</h2>
        <p>
          JUHÁSZ WINE SAFE-DEPOSIT VAULT
          <br style={{display: 'block'}} />
          THE EXPERIENCE OF HAVING YOUR OWN CELLAR, WITH CONVENIENT ACCESS
        </p>
      </header>
    </article>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
            WINE SAFE-DEPOSIT VAULT SERVICE
          </h2>
          <p>
            Keep your precious wines in the most suitable place! Our cellar carved into rhyolite tuff provides all the right conditions for the ideal storage of wine.
          <br style={{display: 'block'}} />
            No cellar of your own? As a wine vault owner, you can experience having your own cellar with us, as we provide the services to ensure that you can conveniently enjoy every drop of your wonderful wines in comfort.
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
            WINE SAFE-DEPOSIT VAULT RENTAL FEE
          </h2>
          <p>
            – 2-shelf vault (suitable for storing 32 bottles of wine) fee: 60,000 HUF+VAT/year
            <br style={{display: 'block'}} />
            – 3-shelf vault (suitable for storing 48 bottles of wine) fee: 90,000 HUF+VAT/year
            <br style={{display: 'block'}} />
              If you purchase at least HUF 180,000 or HUF 270,000 worth of Juhász Brothers Winery’s “Grand Selection” wines, we will give you a 2 or 3-shelf vault, respectively, FREE for 1 year.
          </p>
          <p>
            Amennyiben Ön egy alkalommal legalább 180.000 forint, vagy 270.000 forint értékben vásárol a Juhász Testvérek Pincészete „Grand Selection” boraiból, 2, illetve 3 polcos bortrezorját 1 évre AJÁNDÉKBA adjuk.
          </p>
          <p>
            You can choose the wines for your vault from our range, even on the internet, and we will put the wines you have selected on juhaszvin.hu into your vault. You can pay the bill by bank transfer.
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
          WINE VAULT RENTAL PROCESS
          </h2>
          <p>
          You can request your wine storage by writing to our email address juhaszvin@upcmail.hu. We will send you the WINE SAFE-DEPOSIT RENTAL AGREEMNET and an invoice for the safe-deposit vault rental fee. After we have received payment, we will create the nameplate for your own wine cellar and affix it to your vault.
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5 special ">
      <div className="inner ">
        <header className="major large-header">
          <h2>
          SERVICES PROVIDED TO WINE VAULT RENTERS
          </h2>
          <p>
          - Use of the Juhász Brothers Winery’s exclusive tasting room for wine tasting during opening hours (by prior arrangement)
            <br style={{display: 'block'}} />
            - Professional serving of the Juhász Brothers Winery wines in your vault by our in-house sommelier.
            <br style={{display: 'block'}} />
            - 20% discount on all additional bottled wine purchases, which will be deducted from the current cellar-door price. 
            <br style={{display: 'block'}} />
            - 10% discount on current wine tastings offered by the Juhász Brothers Winery
            <br style={{display: 'block'}} />
            - 10% discount on the current accommodation prices of Anna Panzió in Egerszalók.
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5">
			<div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail" >
                  <LightgalleryItem src={img1}><StaticImage  src="../../assets/images/bortrezor/1.jpg" alt="" /></LightgalleryItem>
                </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2}><StaticImage   src="../../assets/images/bortrezor/2.jpg" alt="" /></LightgalleryItem>
                  </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img3}><StaticImage   src="../../assets/images/bortrezor/3.jpg" alt="" /></LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img4}><StaticImage   src="../../assets/images/bortrezor/4.jpg" alt="" /></LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    
  </Layout>
);

export default TrezorPage;
